import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import { refreshSavedCredentials } from "../modules/auth/authSlice";

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

// if there are any credentials saved in localstorage, then refresh them
store.dispatch(refreshSavedCredentials());

export { store };
