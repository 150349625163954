import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  ThunkAction,
  AnyAction,
} from "@reduxjs/toolkit";
import {
  addAuthorizationBearerToken,
  removeAuthorizationBearerToken,
} from "../../api/punctApi";
import { RootState, Thunk } from "../../redux";
import { UserRole } from "../../generated/graphql";
import { authSerializer } from "./utils/authSerializer";
import { refreshCredentials } from "../auth0/functions/refreshCredentials";

export interface AuthState {
  email?: string;
  userId?: string;
  accessToken?: string;
  companyName?: string;
  name?: string;
  userImage?: string;
  roles?: UserRole[];
  companyId?: number;
  refreshToken?: string;
  expire?: number;
  expiresAt?: number;
}

export interface SuccessfulAuth extends AuthState {
  userId: string;
  accessToken: string;
  refreshToken: string;
}

const initialState: AuthState = authSerializer.load() || {};

const {
  actions: { resetAuthSuccess, updateAuthSuccess },
  reducer: authReducer,
} = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthSuccess: () => ({}),
    updateAuthSuccess: (state, action: PayloadAction<AuthState>) =>
      action.payload,
  },
  extraReducers(builder) {
    builder.addCase(refreshSavedCredentials.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

const updateAuth =
  (state: SuccessfulAuth): Thunk<void> =>
  async (dispatch) => {
    try {
      const { accessToken } = state;

      authSerializer.save(state);

      addAuthorizationBearerToken(accessToken);
      dispatch(updateAuthSuccess(state));
    } catch (err) {
      dispatch(resetAuthSuccess());
    }
  };

const resetAuth = (): Thunk<void> => (dispatch) => {
  authSerializer.reset();
  removeAuthorizationBearerToken();
  dispatch(resetAuthSuccess());
};

let refreshLock = false;

const refreshCredentialsAction =
  (state: SuccessfulAuth): Thunk<void> =>
  async (dispatch) => {
    try {
      if (refreshLock) {
        return;
      }
      refreshLock = true;
      const newCredentials = await refreshCredentials(state.refreshToken);
      if (!newCredentials?.accessToken) {
        refreshLock = false;
        return dispatch(resetAuth());
      }
      dispatch(updateAuth(newCredentials));

      // refresh the new credentials again before they expire
      if (newCredentials.expire) {
        setTimeout(
          () => dispatch(refreshCredentialsAction(newCredentials)),
          (newCredentials.expire - 10) * 1000,
        );
      }

      refreshLock = false;
    } catch (err) {
      console.error(err);
      refreshLock = false;
    }
  };

// async thunk that checks the local storage for saved credentials and refreshes them
// this is used in order to not ask for credentials every time the app loads
const refreshSavedCredentials = createAsyncThunk(
  "auth/refreshSavedCredentials",
  async (_, { dispatch }) => {
    try {
      const initialState = authSerializer.load();
      if (!initialState || !initialState.refreshToken) {
        return {};
      }

      return dispatch(
        refreshCredentialsAction(initialState) as unknown as ThunkAction<
          void,
          unknown,
          unknown,
          AnyAction
        >,
      );
    } catch (err) {
      dispatch(resetAuthSuccess());
    }
  },
);

const getAuthState =
  () =>
  ({ auth }: RootState): AuthState => ({
    userId: auth.userId,
    email: auth.email,
    accessToken: auth.accessToken,
    name: auth.name,
    companyName: auth.companyName,
    userImage: auth.userImage,
    roles: auth.roles,
    companyId: auth.companyId,
    refreshToken: auth.refreshToken,
    expire: auth.expire,
    expiresAt: auth.expiresAt,
  });

const getAccessToken = ({ auth }: RootState): string | undefined =>
  auth.accessToken;

const getUserRoles = ({ auth }: RootState): UserRole[] => auth.roles || [];
const getCompanyId = ({ auth }: RootState) => auth.companyId;

export {
  authReducer,
  getAccessToken,
  getAuthState,
  getUserRoles,
  getCompanyId,
  updateAuth,
  resetAuth,
  refreshCredentialsAction,
  refreshSavedCredentials,
};
