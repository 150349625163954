import { webAuthClient } from "../clients";
import { SuccessfulAuth } from "../../auth/authSlice";
import { getUserInfo } from "./getUserInfo";

interface Props {
  hash: string;
}

const parseHash = ({ hash }: Props) =>
  new Promise<SuccessfulAuth>((resolve, reject) =>
    webAuthClient.parseHash({ hash }, (error, result) => {
      if (
        error ||
        !result?.accessToken ||
        !result?.idToken ||
        !result?.refreshToken ||
        !result?.expiresIn
      ) {
        reject(error);
      } else {
        const { accessToken, idToken, refreshToken, expiresIn } = result;

        getUserInfo({ accessToken, idToken, refreshToken, expiresIn })
          .then(resolve)
          .catch(reject);
      }
    }),
  );

export { parseHash };
