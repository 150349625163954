import { SuccessfulAuth } from "../../auth/authSlice";
import authParams from "../params";
import { authenticationClient } from "../clients";
import { Auth0Error, Auth0Result } from "auth0-js";
import { getUserInfo } from "./getUserInfo";

const refreshCredentials = (refreshToken: string): Promise<SuccessfulAuth> =>
  new Promise((resolve, reject) => {
    authenticationClient.oauthToken(
      {
        grantType: "refresh_token",
        clientId: authParams.client_id,
        refreshToken: refreshToken,
      },
      async (error: Auth0Error | null, result: Auth0Result) => {
        if (error || !result) {
          console.error(error);
          return resolve({} as SuccessfulAuth);
        }

        const { accessToken, idToken, refreshToken, expiresIn } = result;
        if (!accessToken || !idToken || !refreshToken || !expiresIn) {
          const error: Auth0Error = {
            error: "Invalid credentials were received",
          };
          return reject(error);
        }

        const auth = await getUserInfo({
          accessToken,
          idToken,
          refreshToken,
          expiresIn,
        });

        resolve(auth);
      },
    );
  });

export { refreshCredentials };
